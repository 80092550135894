.Home {
    margin-top: 50px;
    display: flex;
    color: white;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /* height: calc(100%-2.5rem); */
    /* flex-flow: column; */
    /* padding-bottom: 5rem;*/
    /* background-color: red; */
}

.desc {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

@media(max-width:599px){
    .Home{
        justify-content: center;
        align-items: center;
        flex-flow: column;
    }
}


/* .AboutPage {
    z-index: 100;
    display: flex;
    flex-flow: column;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    background-color: transparent;
    color: white;
    width: 100%;
    align-items: center;
    text-align: center;
    border-right: 0.5px solid white;
    justify-content: center;
} */

.photo{
    background-color: azure;
    width: 40vw;
    height: 70vh;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    /* margin: 5px; */
    object-fit: cover;
    object-position: 100% 100%;
    /* object-position: 20px 10px; */
    /* cursor: pointer; */
    /* margin: 10px 10px; */
    margin-left: 50px;
}

.photo:hover{
    box-shadow: 0px 0px 150px #000000;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.05);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.05);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.05);
    transition: all 200ms ease-in;
    transform: scale(1.05);
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}


@media(max-width:599px){
    .photo{
        width: 80%;
        margin-left: 0;
        height: 250px;
    }
}

/* .Home img{
    background-color: azure;
    width: 99vw;
    height: 60vh;
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 5px;
    object-fit: cover;
    object-position: 20px 10px;
    cursor: pointer;
} */

.logo:hover{
    animation: enable 0.3s linear;
}

/* .photo:hover{
    transform: scale(1.05);
} */

.Home h1{
    font-family: 'Cinzel', serif;
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.logos{
    display: flex;
    flex-flow: row;
}

.logo{
    width: 50px;
}

/* .resume{
    text-decoration: none;
    color: white;
    align-self: center;
    background-color: red;
} */

.resume {
    /* outline: none; */
    display: flex;
    cursor: pointer;
    height: auto;
    text-decoration: none;
    background-color: transparent;
    width: 30%;
    color: white;
    margin: 20px;
    border: 2px solid white;
    border-radius: 20px;
    font-size: 1.1em;
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* box-shadow: 2px 2px 2px white; */
}

.resume:hover, .Button:active{
    background-color: white;
    color: #000;
    border: 2px solid black;
    animation: enable 0.2s linear;
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}