.Contact {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    width: 65vw;
    /* background-color: aqua; */
    /* font-family: 'Open Sans', sans-serif; */
}

@media(max-width:599px){
    .Contact{
        width: 90vw;
    }
}

.Contact input{
    color: white;
    background-color: rgba(255, 255, 255, 0.0);
}

.Contact textarea{
    color: white;
    background-color: rgba(255, 255, 255, 0.0);
}

.name-email{
    display: flex;
}

@media(max-width:599px){
    .name-email{
        flex-flow: column;
    }
}
.input-wrapper{
    width: 50%;
    padding: 5px;
}
@media(max-width:599px){
    .input-wrapper{
        width: 100%;
    }
}

::placeholder{
    color: whitesmoke;
    padding: 0px 5px;
}

/* .Contact input {
    width: 98%;
    height: 30px;
    /* margin-top: 10px; */
    /* margin-bottom: 10px;
    border: 1px solid black;
    font-family: inherit;
    padding: 0 5px;
}

.Contact ::placeholder{
    font-family: inherit;
} */

.Contact input{
    border: 1px solid white;
    /* border-bottom: 1px solid grey; */
    /* width: 98%; */
    /* width: 50%; */
    border-radius: 5px;
    float: left;
    width: 95%;
    padding: 10px 4px;
    /* height: 30px; */
    font-size: large;
    font-family: inherit;
}

.Contact input:focus{
    outline:none;
    /* border-bottom: 1px solid grey; */
    /* background-color: red; */
}

.Contact textarea{
    width: 98%;
    border: 1px solid white;
    /* border-bottom: 1px solid grey; */
    border-radius: 5px;
    font-family: inherit;
    font-size: large;
}

.Contact textarea:focus{
    outline: none;
}

.Contact p{
    font-size: large;
    color: white;
}
.Contact h1{
    font-size: 250%;
    font-family: 'Dancing Script', cursive;
    color: white;
}

@media(max-width:499px){
    .Contact{

    }
}

@media(max-height:560px){
    .Contact h1{
        font-size: 100%;
    }   

    .Contact textarea{
        font-size: 110%;
    }

    .Contact p{
        font-size: 110%;
    }

    .Contact input{
        font-size: 110%;
    }

    .Contact button{
        font-size: small;
    }
}

@media(max-height:504px){
    .Contact h1{
        font-size: 100%;
    }   

    .Contact textarea{
        font-size: small;
    }

    .Contact p{
        font-size: small;
    }

    .Contact input{
        font-size: small;
    }

    .Contact button{
        font-size: small;
    }
}


.Contact button{
    position: relative;
    /* float: right; */
    display: flex;
    cursor: pointer;
    height: auto;
    background-color: transparent;
    width: fit-content;
    color: white;
    margin: 20px;
    border: 2px solid white;
    border-radius: 25px;
    font-size: 1.1em;
    padding: 10px 55px;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: inherit;
    margin-right: 10px;
}

.Contact button:hover{
    background-color: white;
    color: black;
    border: 2px solid white;
    animation: enable 0.2s linear;
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.Pra {
    color: red;
    position: relative;
    display: inline-block;
    float: right;
    animation-name: shake;
    animation-duration: 3s;
    margin-right: 0px;
    /* background-color: black; */
    /* width:500px; */
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
}



@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .Pra.onload{
      animation: shake 3s linear;
  }