.Content {
    /* margin-top: 56px; */
    /* background-color: skyblue; */
    /*background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    /*width: 100%;
    height: 100vh; */
    /* background-color: skyblue; */
    display: flex;
    width: 100%;
    /* height: 100%; */
    /* min-height: calc(94vh-3rem); */
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* margin-bottom: 2.2rem; */
    position: relative;
    flex-flow: column;
    padding-bottom: 2.5rem;
}

.footer {
    font-size: 10px;
    bottom: 0;
    /* background-color: red; */
    font-family: sans-serif;
    height: 2.5rem;
    /* position: absolute; */
    /* flex-shrink: 0; */
    width: 100%;
}

.body{
    /* min-height: 100vh; */
    margin-top: 56px;
    min-height: 90%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    /* background-color: green; */
}