.Projects {
    margin-top: 15px;
    width: 98vw; 
}



.container{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    flex-flow: row wrap;
    width: 100%;
}

.container::-webkit-scrollbar{
    display: none;
}