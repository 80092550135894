.SkillCard {
  display: flex;
  flex-flow: column;
  height: 5rem;
  width: 10vw;
  margin: 10px 10px 10px 10px;
  box-shadow: 2px 2px 2px grey;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #000000;
  /* position: relative; */
  justify-content: center;
}

@media (max-width: 699px) {
  .SkillCard {
    width: 38vw;
  }
}

.SkillCard:hover {
  box-shadow: 0px 0px 150px #000000;
  z-index: 2;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.05);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.05);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.05);
  transition: all 200ms ease-in;
  /* color: black; */
  transform: scale(1.05);
}

@keyframes enable {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.SkillDesc {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* padding: 10px 0px; */
}

.SkillLogo {
  /* background-color: green; */
  height: 60%;
  position: absolute;
}

.SkillCard img {
  height: 45px;
  width: 45px;
}

.SkillLevel {
  /* background-color: blue; */
  height: 20%;
  padding: 0px 10px;
}
