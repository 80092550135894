.Card {
    width: 20%;
    height: calc(15vw*1.5);
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    text-align: center;
    margin: 10px 10px 10px 10px;
    box-shadow: 2px 2px 2px grey;
    border: 1px solid lightgrey;
    position: relative;
}

@media(max-width:999px){
    .Card {
        width: 25%;
        height: calc(15vw*2);
    }
}

@media(max-width:799px){
    .Card {
        width: 35%;
        height: calc(40vw);
    }
}

@media(max-width:599px){
    .Card {
        width: 35%;
        height: calc(45vw);
    }
}

@media(max-width:499px){
    .Card {
        width: 90%;
        height: calc(100vw*0.95);
    }
}

.Card:hover{
    box-shadow: 0px 0px 150px #000000;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.05);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.05);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.05);
    transition: all 200ms ease-in;
    transform: scale(1.05);
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.pic{
    position: relative;
    float: top;
}

.info{
    /* background-color: whitesmoke; */
    height: 50%;
    position: relative;
    float: bottom;
    display: flex;
    flex-flow: column;
    font-size: small;
    color: white;
}

.Card img{
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
}

/* @media (max-width: 700px){
    .Card{
        width: 90%;
        font-size: small;
    }
}

@media (max-width: 1100px){
    .Card{
        width: 90%;
        font-size: small;
    }
} */

.button{
    /* position: relative; */
    /* float: right; */
    /* display: flex; */
    cursor: pointer;
    /* height: auto; */
    background-color: transparent;
    width: fit-content;
    color: white;
    /* margin: 20px; */
    border: 2px solid white;
    border-radius: 17px;
    font-size: 1.1em;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: inherit;
    margin-right: 10px;
    text-decoration: none;
    margin-bottom: 0px;
}

.button:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
    animation: enable 0.2s linear;
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}